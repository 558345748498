// import { Locale, Lang } from '../Localization/CustomLocalization';

// export const isStaging = true;
export const isStaging = window.location.href.includes('-staging');
const isLocalHost = window.location.href.includes('localhost');

//Jay added 2021.07.05
//fixed variables.
export const GlobalSetting = {
    // BaseUrl: isLocalHost ? 'http://localhost:55557/' : 'https://center.ikey.my/',
    // RootUrl: isLocalHost ? 'http://localhost:3000/' : 'https://livequiz.ikey.my/',
    // SignUpApiUrl: 'https://api.ikey.my/',

    // ApiUrl: 'https://ile-api.ikey.my/',
    // ApiUrl: 'http://localhost:55557/',
    // ApiUrl: isLocalHost ? 'http://localhost:55557/' : 'https://ile-api.ikey.my/',
    ApiUrl: isLocalHost ? 'http://localhost:55557/' : (isStaging ? 'https://ile-api-staging.ikey.my/' : 'https://ile-api.ikey.my/'),

    PageSize: 10,
}

export const Access = {
    All: 'all',
    None: 'none',
    ByPermission: 'byPermission',
    // AuthorOnly: 'authorOnly',
    TeacherOnly: 'teacherOnly',
    TeacherRestrictedOnly: 'teacherRestrictedOnly',
    AdminOnly: 'adminOnly',
    MasterAdminOnly: 'masterAdminOnly',
    SuperAdminOnly: 'superAdminOnly'
}

//2024.09.23
const Permissions = {
    Dashboard: 'dashboard',
    //basic.
    ManageRoom: 'manageRoom',
    // ManageRoomResult: 'manageRoomResult',   //2024.07.19
    RoomTypeFile: 'manageRoomTypeFile',
    RoomTypeFileDetail: 'manageRoomTypeFileDetail',
    ManageQuestionSet: 'manageQuestionSet',
    EditQuestionSet: 'editQuestionSet',
    ManageStudentProfile: 'manageStudentProfile',
    ManageTeacherProfile: 'manageTeacherProfile',

    //report.
    ManageReportRoomsResult: 'manageReportRoomsResult',     //2024.07.06    //2024.07.29
    ManageReportAbsentStudent: 'manageReportAbsentStudent',
    ManageReportStudentStatistic: 'manageReportStudentStatistic',

    //admin.
    ManageSetting: 'manageSetting',
    ManageReport: 'manageReport',   //2024.07.29
    ManageCustomGroup: 'manageCustomGroup',
    ManageEducationStage: 'manageEducationStage',
    ManageCustomSubject: 'manageCustomSubject', //2024.07.31
    ManageClassroom: 'manageClassroom', //2024.09.28
    ManageGroup: 'manageGroup', //2024.11.05

    //super admin.
    ManageAuthor: 'manageAuthor',
    ManageOrganizer: 'manageOrganizer',

    //others.
    ManageEvent: 'manageEvent',
    LiveQuizRankingList: 'liveQuizRankingList',
    EventParticipationReportLite: 'eventParticipationReport',
}

export const LayoutScreen = {

    None: '',
    Invalid: '9999',
    Login: 'login',

    //CONTROL.
    Dashboard: Permissions.Dashboard,
    // Home: 'homeScreen',
    ManageRoom: Permissions.ManageRoom,
    // ManageRoomResult: 'manageRoomResult',   //2024.07.19
    RoomTypeFile: Permissions.RoomTypeFile,
    RoomTypeFileDetail: Permissions.RoomTypeFileDetail,
    ManageQuestionSet: Permissions.ManageQuestionSet,
    EditQuestionSet: Permissions.EditQuestionSet,
    ManageStudentProfile: Permissions.ManageStudentProfile,
    ManageTeacherProfile: Permissions.ManageTeacherProfile,

    //ACTIVITIES.


    //REPORT.
    ManageReport: Permissions.ManageReport,   //2024.07.29
    ManageReportRoomsResult: Permissions.ManageReportRoomsResult,     //2024.07.06    //2024.07.29
    ManageReportAbsentStudent: Permissions.ManageReportAbsentStudent,
    ManageReportStudentStatistic: Permissions.ManageReportStudentStatistic,

    //SETTING.
    ManageSetting: Permissions.ManageSetting,
    ManageCustomGroup: Permissions.ManageCustomGroup,
    ManageEducationStage: Permissions.ManageEducationStage,
    ManageCustomSubject: Permissions.ManageCustomSubject, //2024.07.31
    ManageClassroom: Permissions.ManageClassroom, //2024.09.28
    ManageGroup: Permissions.ManageGroup,   //2024.11.05

    //super admin.
    ManageAuthor: Permissions.ManageAuthor,
    ManageOrganizer: Permissions.ManageOrganizer,

    //others.
    ManageEvent: Permissions.ManageEvent,
    LiveQuizRankingList: Permissions.LiveQuizRankingList,
    EventParticipationReportLite: Permissions.EventParticipationReportLite,
}

export const Menu = {
    home: {
        Label: 'Home',
        Link: '/',
        Screen: LayoutScreen.None,
        Show: Access.None,
        ShowInMenu: false,
        Disabled: false,
    },
    dashboard: {
        Label: 'Dashboard',
        Link: '/dashboard',
        Screen: LayoutScreen.Dashboard,
        Show: Access.All,
        ShowInMenu: true,
        Disabled: false,
    },
    room: {
        Label: 'Room',
        Link: '/room',
        Screen: LayoutScreen.ManageRoom,
        Show: Access.ByPermission,
        ShowInMenu: true,
        Disabled: false,
    },
    questionSet: {
        Label: 'Question Set',
        Link: '/question-set',
        Screen: LayoutScreen.ManageQuestionSet,
        Show: Access.ByPermission,
        ShowInMenu: true,
        Disabled: false,
    },
    student: {
        Label: 'Student',
        Link: '/student',
        Screen: LayoutScreen.ManageStudentProfile,
        Show: Access.ByPermission,
        ShowInMenu: true,
        Disabled: false,
    },
    // studentStatisticReport: {
    //     Label: 'Student Statistic Report',
    //     Link: '/student-statistic-report',
    //     Screen: LayoutScreen.ManageReportStudentStatistic,
    //     Show: Access.All,
    //     ShowInMenu: true,
    //     Disabled: false,
    // },
    // absentStudentReport: {
    //     Label: 'Absent Student Report',
    //     Link: '/absent-student-report',
    //     Screen: LayoutScreen.ManageReportAbsentStudent,
    //     Show: Access.All,
    //     ShowInMenu: true,
    //     Disabled: false,
    // },
    teacher: {
        Label: 'Teacher',
        Link: '/teacher',
        Screen: LayoutScreen.ManageTeacherProfile,
        Show: Access.ByPermission,
        ShowInMenu: true,
        Disabled: false,
    },
    author: {
        Label: 'Author',
        Link: '/author',
        Screen: LayoutScreen.ManageAuthor,
        Show: Access.SuperAdminOnly,
        ShowInMenu: true,
        Disabled: false,
    },
    organizer: {
        Label: 'Organizer',
        Link: '/organizer',
        Screen: LayoutScreen.ManageOrganizer,
        Show: Access.SuperAdminOnly,
        ShowInMenu: true,
        Disabled: false,
    },
    editQuestionSet: {
        Label: 'Question Set',
        Link: '/question-set/edit',
        Screen: LayoutScreen.EditQuestionSet,
        Show: Access.ByPermission,
        ShowInMenu: false,
        Disabled: false,
    },
    settings: {
        Label: 'Settings',
        Link: '/settings',
        Screen: LayoutScreen.ManageSetting,
        Show: Access.ByPermission,
        ShowInMenu: true,
        Disabled: false,
    },
    reports: {
        Label: 'Reports',
        Link: '/reports',
        Screen: LayoutScreen.ManageReport,
        Show: Access.ByPermission,
        ShowInMenu: true,
        Disabled: false,
    },
    events: {
        Label: 'Events',
        Link: '/events',
        Screen: LayoutScreen.ManageEvent,
        Show: Access.ByPermission,
        ShowInMenu: true,
        Disabled: false,
    },
};
// export const MenuReverse = [...Menu].reverse();
export const MenuReverse = Object.keys(Menu).reverse().map((name, key) => {
    return Menu[name];
});

//2024.09.13
export const getMenuOptions = () => {
    let menuOptions = [];
    Object.keys(Menu).map((name, key) => {
        if (Menu[name].ShowInMenu === true)
            // menuItems.push(Menu[name]);
            menuOptions.push({ value: Menu[name].Link, label: Menu[name].Label, id: Menu[name].Screen, });
        return null;
    });
    return menuOptions;
}

export const getMenu = (layout = LayoutScreen.None) => {
    let menu = { Label: 'Login', Link: '/', Screen: LayoutScreen.Login, Show: Access.All };
    if (layout === LayoutScreen.None)
        return menu;
    // const findIndex = Menu.findIndex(x => x.Screen === layout);
    // if (findIndex > -1)
    //     return Menu[findIndex];
    Object.keys(Menu).map((name, key) => {
        if (Menu[name].Screen === layout)
            menu = Menu[name];
        return null;
    });
    return menu;
}

export const getMenuLink = (layout = LayoutScreen.None) => {
    const menu = getMenu(layout);
    return menu.Link;
}

export const SettingMenu = {
    customGroup: {
        //0
        Label: 'Custom Group',
        Link: '/' + LayoutScreen.ManageCustomGroup,
        Screen: LayoutScreen.ManageCustomGroup,
        Show: Access.ByPermission,
        ShowInMenu: true,
        Disabled: false,
    },
    educationStage: {
        //1
        Label: 'Education Stage',
        Link: '/' + LayoutScreen.ManageEducationStage,
        Screen: LayoutScreen.ManageEducationStage,
        Show: Access.ByPermission,
        ShowInMenu: true,
        Disabled: false,
    },
    customSubject: {
        //2
        Label: 'Manage Subject',
        Link: '/' + LayoutScreen.ManageCustomSubject,
        Screen: LayoutScreen.ManageCustomSubject,
        Show: Access.ByPermission,
        ShowInMenu: true,
        Disabled: false,
    },
    classroom: {
        //3
        Label: 'Manage Classroom',
        Link: '/' + LayoutScreen.ManageClassroom,
        Screen: LayoutScreen.ManageClassroom,
        Show: Access.ByPermission,
        ShowInMenu: true,
        Disabled: false,
    },
    group: {
        //4
        Label: 'Manage Group',
        Link: '/' + LayoutScreen.ManageGroup,
        Screen: LayoutScreen.ManageGroup,
        Show: Access.ByPermission,
        ShowInMenu: true,
        Disabled: false,
    },
}

export const ReportMenu = {
    roomResult: {
        //0
        Label: 'Rooms Result Report',
        Link: '/' + LayoutScreen.ManageReportRoomsResult,
        Screen: LayoutScreen.ManageReportRoomsResult,
        Show: Access.All,
        ShowInMenu: true,
        Disabled: false,
    },
    absentStudent: {
        //1
        Label: 'Absent Student Report',
        Link: '/' + LayoutScreen.ManageReportAbsentStudent,
        Screen: LayoutScreen.ManageReportAbsentStudent,
        Show: Access.All,
        ShowInMenu: false,
        Disabled: false,
    },
    studentStatistic: {
        //2
        Label: 'Student Statistic Report',
        Link: '/' + LayoutScreen.ManageReportStudentStatistic,
        Screen: LayoutScreen.ManageReportStudentStatistic,
        Show: Access.AdminOnly,
        ShowInMenu: false,
        Disabled: false,
    },
}

export const ReportStages = {
    Init: 'Init',
    InfoTab: 'InfoTab',
    GroupTab: 'GroupTab',
    SubjectTab: 'SubjectTab',   //new.
    ClassroomTab: 'ClassroomTab',
    Processing: 'Processing',
    Success: 'Success',
    Error: 'Error',
};

//2023.09.11
export const UploadState = {
    None: 'None',
    Converting: 'Converting',
    ConvertFailed: 'ConvertFailed',
    Uploading: 'Uploading',
    UploadFailed: 'UploadFailed',
    Processing: 'Processing',
    Failed: 'Failed',
    Success: 'Success',
    Saving: 'Saving',
    Validation: 'Validation',
};

//2023.09.14
export const CommonState = {
    None: 'None',
    New: 'New',
    View: 'View',
    Edit: 'Edit',
    Delete: 'Delete',
    Processing: 'Processing',
    Failed: 'Failed',
    Success: 'Success',
};

//2023.09.15
export const InputType = {
    None: 'none',
    Text: 'text',
    Number: 'number',
    Checkbox: 'checkbox',
    Radio: 'radio',
    Select: 'select',
    // SelectWithPreCondition: 'selectWithPreCondition',
    SelectWithInputDetection: 'selectWithInputDetection',
};

//2023.09.21
export const PermissionAccessType = {
    None: '',
    View: 'view',
    Search: 'search',
    Download: 'download',
    Upload: 'upload',
    Create: 'create',
    Update: 'update',
    Delete: 'delete',
}

//2024.01.08
export const SecretKey = {
    Admin: '722B229070E44A6582034C1BAFFF8644',
    Common: '7Np5EkDX4SlgdaVITCh9JQbU',
    // Scheduler: '796Za7RZ4A9EqYjfFCX65NFq',
    // AiStation: '39ffcc60-a593-47eb-8040-c177db9e34e6',
}

export const AtoZ = [
    'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H',
    'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P',
    'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'
];

export const QuizType = [
    //Index = must linear asc order numbered, Id = may vary in sequence, Hidden = to toggle option availability.
    { Index: 1, Id: 1, Name: 'Objective', Hidden: false },
    { Index: 2, Id: 2, Name: 'Subjective', Hidden: true },
    { Index: 3, Id: 3, Name: 'Comprehension', Hidden: false },
    { Index: 4, Id: 4, Name: 'FillInTheBlanks', Hidden: false },       //original type = all questions shared same answers set.
    { Index: 5, Id: 5, Name: 'FillInTheBlankType1', Hidden: true },    //type 1 = each question has its own answers set.
    { Index: 6, Id: 6, Name: 'FillInTheBlankType2', Hidden: true },    //type 2 = ...
    { Index: 7, Id: 7, Name: 'DragAndDrops', Hidden: true },           //shared type = all questions shared same answers set.
    { Index: 8, Id: 8, Name: 'DragAndDropType1', Hidden: true },       //type 1 = each question has its own answers set.
    { Index: 9, Id: 9, Name: 'DragAndDropType2', Hidden: true },       //type 2 = ...
];

export const regex_email = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const ToastPosition = {
    TopStart: 'top-start',
    TopCenter: 'top-center',
    TopEnd: 'top-end',
    MiddleStart: 'middle-start',
    MiddleCenter: 'middle-center',
    MiddleEnd: 'middle-end',
    BottomStart: 'bottom-start',
    BottomCenter: 'bottom-center',
    BottomEnd: 'bottom-end',
}

//2024.07.22
export const DefaultCustomPermissionModel_Allowed = {
    view: true,
    search: true,
    download: true,
    upload: true,
    create: true,
    update: true,
    delete: true,
};
export const DefaultCustomPermissionModel_Restricted = {
    view: false,
    search: false,
    download: false,
    upload: false,
    create: false,
    update: false,
    delete: false,
};
export const DefaultCustomPermissionModel_ViewOnly = {
    view: true,
    search: true,
    download: false,
    upload: false,
    create: false,
    update: false,
    delete: false,
};
export const BasicRolePermissions = [
    { manageRoom: DefaultCustomPermissionModel_Allowed },
    { manageQuestionSet: DefaultCustomPermissionModel_Allowed },
    { manageStudentProfile: DefaultCustomPermissionModel_Allowed },

    { manageReport: DefaultCustomPermissionModel_Allowed },
    { manageReportRoomsResult: DefaultCustomPermissionModel_Allowed },
    { manageReportStudentStatistic: DefaultCustomPermissionModel_Allowed },
    { manageReportAbsentStudent: DefaultCustomPermissionModel_Allowed },

    { manageTeacherProfile: DefaultCustomPermissionModel_Restricted },

    { manageSetting: DefaultCustomPermissionModel_Restricted },
    { manageCustomGroup: DefaultCustomPermissionModel_Restricted },
    { manageCustomSubject: DefaultCustomPermissionModel_Restricted },
    { manageEducationStage: DefaultCustomPermissionModel_Restricted },
    { manageClassroom: DefaultCustomPermissionModel_Restricted },
    { manageGroup: DefaultCustomPermissionModel_Restricted },

    { manageAuthor: DefaultCustomPermissionModel_Restricted },
    { manageOrganizer: DefaultCustomPermissionModel_Restricted },
    { manageEvent: DefaultCustomPermissionModel_Restricted }
];
export const TeacherRolePermissions = [
    { manageRoom: DefaultCustomPermissionModel_Restricted },
    { manageQuestionSet: DefaultCustomPermissionModel_Restricted },
    { manageStudentProfile: DefaultCustomPermissionModel_Restricted },

    { manageReport: DefaultCustomPermissionModel_Allowed },
    { manageReportRoomsResult: DefaultCustomPermissionModel_Allowed },
    { manageReportStudentStatistic: DefaultCustomPermissionModel_Allowed },
    { manageReportAbsentStudent: DefaultCustomPermissionModel_Allowed },

    { manageTeacherProfile: DefaultCustomPermissionModel_Restricted },

    { manageSetting: DefaultCustomPermissionModel_Restricted },
    { manageCustomGroup: DefaultCustomPermissionModel_Restricted },
    { manageCustomSubject: DefaultCustomPermissionModel_Restricted },
    { manageEducationStage: DefaultCustomPermissionModel_Restricted },
    { manageClassroom: DefaultCustomPermissionModel_Restricted },
    { manageGroup: DefaultCustomPermissionModel_Restricted },

    { manageAuthor: DefaultCustomPermissionModel_Restricted },
    { manageOrganizer: DefaultCustomPermissionModel_Restricted },
    { manageEvent: DefaultCustomPermissionModel_Restricted }
];
export const AdminRolePermissions = [
    { manageRoom: DefaultCustomPermissionModel_Allowed },
    { manageQuestionSet: DefaultCustomPermissionModel_Allowed },
    { manageStudentProfile: DefaultCustomPermissionModel_Allowed },

    { manageReport: DefaultCustomPermissionModel_Allowed },
    { manageReportRoomsResult: DefaultCustomPermissionModel_Allowed },
    { manageReportStudentStatistic: DefaultCustomPermissionModel_Allowed },
    { manageReportAbsentStudent: DefaultCustomPermissionModel_Allowed },

    { manageTeacherProfile: DefaultCustomPermissionModel_Allowed },

    { manageSetting: DefaultCustomPermissionModel_Allowed },
    { manageCustomGroup: DefaultCustomPermissionModel_Allowed },
    { manageCustomSubject: DefaultCustomPermissionModel_Allowed },
    { manageEducationStage: DefaultCustomPermissionModel_Allowed },
    { manageClassroom: DefaultCustomPermissionModel_Allowed },
    { manageGroup: DefaultCustomPermissionModel_Allowed },

    { manageAuthor: DefaultCustomPermissionModel_Restricted },
    { manageOrganizer: DefaultCustomPermissionModel_Restricted },
    { manageEvent: DefaultCustomPermissionModel_Restricted }
];
export const MasterRolePermissions = [
    { manageRoom: DefaultCustomPermissionModel_Allowed },
    { manageQuestionSet: DefaultCustomPermissionModel_Allowed },
    { manageStudentProfile: DefaultCustomPermissionModel_Allowed },

    { manageReport: DefaultCustomPermissionModel_Allowed },
    { manageReportRoomsResult: DefaultCustomPermissionModel_Allowed },
    { manageReportStudentStatistic: DefaultCustomPermissionModel_Allowed },
    { manageReportAbsentStudent: DefaultCustomPermissionModel_Allowed },

    { manageTeacherProfile: DefaultCustomPermissionModel_Allowed },

    { manageSetting: DefaultCustomPermissionModel_Allowed },
    { manageCustomGroup: DefaultCustomPermissionModel_Allowed },
    { manageCustomSubject: DefaultCustomPermissionModel_Allowed },
    { manageEducationStage: DefaultCustomPermissionModel_Allowed },
    { manageClassroom: DefaultCustomPermissionModel_Allowed },
    { manageGroup: DefaultCustomPermissionModel_Allowed },

    { manageAuthor: DefaultCustomPermissionModel_Restricted },
    { manageOrganizer: DefaultCustomPermissionModel_Restricted },
    { manageEvent: DefaultCustomPermissionModel_Restricted }
];
export const SuperAdminRolePermissions = [
    { manageRoom: DefaultCustomPermissionModel_Allowed },
    { manageQuestionSet: DefaultCustomPermissionModel_Allowed },
    { manageStudentProfile: DefaultCustomPermissionModel_Allowed },

    { manageReport: DefaultCustomPermissionModel_Allowed },
    { manageReportRoomsResult: DefaultCustomPermissionModel_Allowed },
    { manageReportStudentStatistic: DefaultCustomPermissionModel_Allowed },
    { manageReportAbsentStudent: DefaultCustomPermissionModel_Allowed },

    { manageTeacherProfile: DefaultCustomPermissionModel_Allowed },

    { manageSetting: DefaultCustomPermissionModel_Allowed },
    { manageCustomGroup: DefaultCustomPermissionModel_Allowed },
    { manageCustomSubject: DefaultCustomPermissionModel_Allowed },
    { manageEducationStage: DefaultCustomPermissionModel_Allowed },
    { manageClassroom: DefaultCustomPermissionModel_Allowed },
    { manageGroup: DefaultCustomPermissionModel_Allowed },

    { manageAuthor: DefaultCustomPermissionModel_Allowed },
    { manageOrganizer: DefaultCustomPermissionModel_Allowed },
    { manageEvent: DefaultCustomPermissionModel_Allowed }
];
export const ViewerRolePermissions = [
    { manageRoom: DefaultCustomPermissionModel_ViewOnly },
    { manageQuestionSet: DefaultCustomPermissionModel_ViewOnly },
    { manageStudentProfile: DefaultCustomPermissionModel_ViewOnly },

    { manageReport: DefaultCustomPermissionModel_ViewOnly },
    { manageReportRoomsResult: DefaultCustomPermissionModel_ViewOnly },
    { manageReportStudentStatistic: DefaultCustomPermissionModel_ViewOnly },
    { manageReportAbsentStudent: DefaultCustomPermissionModel_ViewOnly },

    { manageTeacherProfile: DefaultCustomPermissionModel_Restricted },

    { manageSetting: DefaultCustomPermissionModel_Restricted },
    { manageCustomGroup: DefaultCustomPermissionModel_Restricted },
    { manageCustomSubject: DefaultCustomPermissionModel_Restricted },
    { manageEducationStage: DefaultCustomPermissionModel_Restricted },
    { manageClassroom: DefaultCustomPermissionModel_Restricted },
    { manageGroup: DefaultCustomPermissionModel_Restricted },

    { manageAuthor: DefaultCustomPermissionModel_Restricted },
    { manageOrganizer: DefaultCustomPermissionModel_Restricted },
    { manageEvent: DefaultCustomPermissionModel_Restricted }
];