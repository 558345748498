import en from './english.js';
import chi from './chinese.js';
import mal from './malay.js';

let translations = {
  chi,
  en,
  mal
};

let Locale = (
  key = '',
  locale = 'en',
  insert = '',
) => {
  let currTranslation = translations[locale] ? translations[locale] : en;
  let transWord = currTranslation[key]
    ? currTranslation[key]
    : en[key]
      ? en[key]
      : key;

  //2021.11.16
  if (String(transWord).includes('<>'))
    transWord = String(transWord).replace('<>', insert);

  return transWord;
};

const Lang = {
  English: "en",
  Chinese: "chi",
  Malay: "mal"
}

export { Locale, Lang };