import React from "react";
import { Redirect } from "react-router-dom/cjs/react-router-dom";
import { Button, Dropdown, Modal, SplitButton, Toast, ToastContainer } from "react-bootstrap";

import ileLogo from '../images/ile-logo.jpg';
import { useGlobal } from "../utilities/GlobalVariables";
import { GlobalSetting, LayoutScreen, Menu, SecretKey, ToastPosition } from "../utilities/GlobalSetting";
import { AdjustSectionRightDiv, CapitalizeJsonKeys, CheckBoolean, CheckNullValue, CheckObjectBoolean, CheckObjectNullValue, CheckObjectNumber, CheckObjectStringEmpty, CheckStringEmpty, Delay, GetPropIds, RandomId, SetTempTarget, ShowOrHideMenu, ToBase64 } from "../utilities/GlobalFunctions";
import { useAppService } from "../services/AppService";
import { AlertMode } from "./AlertComponent";
import { UploadPictureFileForOrganizer_ViaAPI } from "../services/OrganizerService";

export default class MainLayout extends React.Component {

    constructor(props) {
        super(props);
        this.state = this.getInitState();   //all states will get refresh everytime enter this page.
        // this.Ref_Toast = React.createRef();
    }

    getInitState = () => ({

        isDevMode: window.location.href.includes('localhost'),
        locale: CheckStringEmpty(useGlobal.getState().locale, 'en'),
        redirect: false,
        redirectLink: '/',
        isLoading: false,
        freezeAllControls: false,

        screen: LayoutScreen.None,
        isSuperAdmin: false,
        isMasterAdmin: false,
        isAdmin: false,
        isAuthor: false,
        isTeacher: false,   //2024.07.23

        authorName: CheckStringEmpty(useGlobal.getState().authorName),
        email: CheckStringEmpty(useGlobal.getState().email),
        organizerInfo: null,
        organizerList: [],

        Toast_Toggle: false,
        Toast_Setting: { title: '', time: '', body: '' },

        EditProfileModal_Toggle: false,
        TargetProfile: null,
        CachedProfile: null,
        AttachedPictureFile: null,
        PictureFileName: '',
    });

    componentWillUnmount = () => {
        useGlobal.getState().setRefreshListCallbackFn(null);
        window.removeEventListener('resize', AdjustSectionRightDiv);
    }

    componentDidMount = async () => {
        useAppService.getState().setModal();
        useGlobal.getState().setRefreshListCallbackFn(null);
        const globalState = useGlobal.getState();
        this.setState({
            screen: CheckStringEmpty(globalState.screen, LayoutScreen.None),
            isSuperAdmin: CheckBoolean(globalState.isSuperAdmin),
            isMasterAdmin: CheckBoolean(globalState.isMasterAdmin),
            isAdmin: CheckBoolean(globalState.isAdmin),
            isAuthor: CheckBoolean(globalState.isAuthor),
            isTeacher: CheckBoolean(globalState.isTeacher),
            // isTeacher: CheckObjectBoolean(globalState.user, 'IsTeacher'),   //2024.07.23
            // organizerInfo: CheckObjectNullValue(globalState, 'organizerInfo', CheckObjectNullValue(globalState, 'OrganizerInfo')),
            organizerInfo: CheckObjectNullValue(globalState, 'organizerInfo'),
            organizerList: CheckObjectNullValue(globalState.user, 'OrganizerInfoList', []),
        }, () => {
            if (this.state.isDevMode) {
                console.log('MainLayout (componentDidMount) =\n' + JSON.stringify(this.state.organizerInfo));
                console.log(`MainLayout (componentDidMount) =\n isTeacher = ${String(this.state.isTeacher)}`);
                console.log(`MainLayout (componentDidMount) =\n isAdmin = ${String(this.state.isAdmin)}`);
                console.log(`MainLayout (componentDidMount) =\n isMasterAdmin = ${String(this.state.isMasterAdmin)}`);
                console.log(`MainLayout (componentDidMount) =\n isSuperAdmin = ${String(this.state.isSuperAdmin)}`);
            }
        });
        await Delay(0);
        if (globalState.user === null)
            this.setState({ redirect: true, redirectLink: '/' });     //back to login.
        await Delay(200);
        this.initialMenuButtons();
        useAppService.getState().setToggleToast(this.toggleToast);
        AdjustSectionRightDiv();
        window.addEventListener('resize', AdjustSectionRightDiv);

        //remove all iframe.
        if (this.state.isDevMode) {
            const frames = document.getElementsByTagName("iframe");
            for (let i = 0; i < frames.length; ++i) {
                frames[i].remove();
            }
            // console.log(`MainLayout (componentDidMount) =\n ${JSON.stringify(globalState.user['CustomPermissions'])}`);
        }
    }

    //#region === common ===
    // adjustSectionRightDiv = () => {
    //     const com = document.getElementById('section-right-div');
    //     if (com != null) {
    //         const width = com.getBoundingClientRect().width;
    //         const innerWidth = Math.floor(window.innerWidth * 0.99);
    //         // console.log(com.classList.toString() + ' / ' + innerWidth + ' / ' + width);
    //         com.style.left = (innerWidth - width + 10) + 'px';
    //     }
    // }
    initialMenuButtons = () => {

        if (this.state.isTeacher)
            return null;

        //menu button.
        let parentNode = null;
        let primaryBtnCom = null;
        let splitBtnCom = document.getElementById('main-layout-split-btn-menu');
        if (splitBtnCom !== null)
            parentNode = splitBtnCom.parentNode;
        if (parentNode !== null) {
            const buttons = Array.from(parentNode.childNodes).filter((node) => {
                return node.nodeType === 1 && node.classList.contains('btn-primary');
            });
            if (buttons !== null && Array.isArray(buttons))
                primaryBtnCom = buttons[0];
        }
        if (primaryBtnCom !== null)
            primaryBtnCom.addEventListener('click', useGlobal.getState().refreshListCallbackFn);
        if (this.state.isDevMode)
            console.log('initialMenuButtons (menu) =\n' + (primaryBtnCom !== null ? primaryBtnCom.textContent : 'n/a'));

        //organizer button.
        splitBtnCom = null;
        parentNode = null;
        primaryBtnCom = null;
        splitBtnCom = document.getElementById('main-layout-split-btn-organizers');
        if (splitBtnCom !== null)
            parentNode = splitBtnCom.parentNode;
        if (parentNode !== null) {
            const buttons = Array.from(parentNode.childNodes).filter((node) => {
                return node.nodeType === 1 && node.classList.contains('btn-primary');
            });
            if (buttons !== null && Array.isArray(buttons))
                primaryBtnCom = buttons[0];
        }
        if (primaryBtnCom !== null)
            primaryBtnCom.addEventListener('click', useGlobal.getState().refreshListCallbackFn);
        if (this.state.isDevMode)
            console.log('initialMenuButtons (organizer) =\n' + (primaryBtnCom !== null ? primaryBtnCom.textContent : 'n/a'));
    }
    getMenuTitle = (screen = LayoutScreen.None) => {
        return useGlobal.getState().menuLabel;    //2024.09.13
        // if (screen === LayoutScreen.None)
        //     return '';
        // const keys = Object.keys(Menu);
        // let title = Menu[keys[0]].Label;
        // for (let i = 0; i < keys.length; i++) {
        //     if (Menu[keys[i]].Screen === screen) {
        //         title = Menu[keys[i]].Label;
        //         break;
        //     }
        // }
        // return title;
    }
    getOrganizerTitle = (organizerInfo = null) => {
        if (organizerInfo === null || this.state.organizerList.length === 0)
            return '';
        let organizerDisplayName = '';
        for (let i = 0; i < this.state.organizerList.length; i++) {
            if (this.state.organizerList[i].Identity === organizerInfo.Identity) {
                organizerDisplayName = this.state.organizerList[i].DisplayName;
                break;
            }
        }
        return organizerDisplayName;
    }
    gotoScreen = (label = '', screen = LayoutScreen.None, link = '') => {
        if (this.state.isDevMode)
            console.log('gotoScreen = ' + label + '\n' + screen + '\n' + link);
        link = CheckStringEmpty(link);
        if (link.length > 0) {
            useGlobal.getState().setMenuLabel(label);
            useGlobal.getState().setScreen(screen);
            this.setState({ redirectLink: link, redirect: true, });
        }
    }
    toggleToast = async (title = '', time = '', body = '', position = ToastPosition.TopEnd) => {
        const toggle = !this.state.Toast_Toggle;
        if (toggle) {
            this.setState({
                Toast_Setting: { title: title, time: time, body: body, position: position, },
                Toast_Toggle: toggle,
            });
            await Delay(2500);
            this.toggleToast();
        }
        else {
            this.setState({
                Toast_Toggle: toggle,
            }, async () => {
                await Delay(500);
                this.setState({
                    Toast_Setting: { title: title, time: time, body: body, position: position, },
                });
            });
        }
    }
    // showOrHideMenu = (menuItem = null) => {
    //     if (menuItem === null)
    //         return false;

    //     if (this.state.isSuperAdmin)
    //         return true;

    //     if (menuItem.Show === Access.MasterAdminOnly && this.state.isMasterAdmin)
    //         return true;

    //     if (menuItem.Show === Access.AdminOnly && this.state.isAdmin)
    //         return true;

    //     if (menuItem.Show === Access.TeacherOnly && this.state.isTeacher)
    //         return true;

    //     if (menuItem.Show === Access.All) {
    //         switch (CheckObjectStringEmpty(menuItem, 'Screen')) {
    //             case LayoutScreen.Dashboard: return !this.state.isTeacher;
    //             case LayoutScreen.ManageRoom: return PermissionAccess(LayoutScreen.ManageRoom, PermissionAccessType.View);
    //             case LayoutScreen.ManageQuestionSet: return PermissionAccess(LayoutScreen.ManageQuestionSet, PermissionAccessType.View);
    //             case LayoutScreen.ManageStudentProfile: return PermissionAccess(LayoutScreen.ManageStudentProfile, PermissionAccessType.View);
    //             case LayoutScreen.ManageTeacherProfile: return PermissionAccess(LayoutScreen.ManageTeacherProfile, PermissionAccessType.View);
    //             case LayoutScreen.ManageSetting: return PermissionAccess(LayoutScreen.ManageSetting, PermissionAccessType.View);
    //             case LayoutScreen.ManageReport: return PermissionAccess(LayoutScreen.ManageReport, PermissionAccessType.View);
    //             default: break;
    //         }
    //     }
    //     return false;
    // }
    //#endregion === common ===

    //#region === organizer profile ===
    ToggleEditOrganizerProfileUi = () => {
        this.setState({
            EditProfileModal_Toggle: !this.state.EditProfileModal_Toggle,
        }, () => {
            if (this.state.EditProfileModal_Toggle) {
                //open.
                const profile = JSON.parse(JSON.stringify(this.state.organizerInfo));
                SetTempTarget(profile);
                this.setState({
                    TargetProfile: profile,
                    CachedProfile: JSON.parse(JSON.stringify(this.state.organizerInfo)),
                });
            }
            else {
                //close.
                this.setState({
                    TargetProfile: null,
                    CachedProfile: null,
                });
            }
        });
    }
    ResetEditOrganizerProfile = () => {
        this.setState({
            TargetProfile: JSON.parse(JSON.stringify(this.state.CachedProfile)),
        }, () => {
            // this.CallbackSaveTarget(this.state.TargetProfile);
        });
    }
    // CallbackSaveTarget = (tempTarget = null) => {
    //     if (CheckNullValue(tempTarget) !== null) {
    //         this.setState({
    //             TargetProfile: tempTarget,
    //         });
    //     }
    // }
    UpdateValues = async (itemProperty = '', value = null) => {
        if (this.state.isTeacher)
            return null;
        let targetProfile = this.state.TargetProfile;
        switch (itemProperty) {
            case 'DisplayName': targetProfile['DisplayName'] = String(value); break;
            case 'SchoolAddress': targetProfile['SchoolAddress'] = String(value); break;
            default: break;
        }
        if (this.state.isDevMode)
            console.log(`UpdateValues (${itemProperty}) = ${JSON.stringify(value)} \n${JSON.stringify(targetProfile)}`);
        this.setState({
            TargetProfile: targetProfile,
        });
        await Delay(0);
    }
    ProfileComponents = () => {
        let components = [];
        const targetProfile = this.state.TargetProfile;

        //DisplayName.
        components.push(<div key='profile-display-name' className="form-group">
            <label>Display Name *</label>
            <input
                name="DisplayName"
                className={"form-control"}
                type="text"
                value={targetProfile === null ? '' : String(targetProfile['DisplayName'])}
                placeholder={CheckObjectStringEmpty(targetProfile, 'DisplayName', 'Display Name')}
                onChange={(e) => this.UpdateValues('DisplayName', e.target.value)}
                disabled={this.state.isTeacher}
            />
        </div>);
        // components.push(<div key='profile-display-name' className="form-group">
        //     <label>Display Name *</label>
        //     {GetInputComponent(InputType.Text, null, targetProfile, 'DisplayName', null, 'Display Name', this.state.locale, this.CallbackSaveTarget, null, false)}
        // </div>);

        //SchoolAddress.
        components.push(<div key='profile-school-address' className="form-group">
            <label>School Address</label>
            <input
                name="SchoolAddress"
                className={"form-control"}
                type="text"
                value={targetProfile === null ? '' : String(targetProfile['SchoolAddress'])}
                placeholder={CheckObjectStringEmpty(targetProfile, 'SchoolAddress', 'School Address')}
                onChange={(e) => this.UpdateValues('SchoolAddress', e.target.value)}
                disabled={this.state.isTeacher}
            />
        </div>);
        // components.push(<div key='profile-school-address' className="form-group">
        //     <label>School Address</label>
        //     {GetInputComponent(InputType.Text, null, targetProfile, 'SchoolAddress', null, 'School Address', this.state.locale, this.CallbackSaveTarget, null, false)}
        // </div>);

        //Logo.
        const logoUrl = CheckObjectStringEmpty(targetProfile, 'LogoUrl');
        const logoFileName_splits = logoUrl.split('/');
        const logoFileName = logoUrl.length === 0 ? 'Logo' : logoFileName_splits[logoFileName_splits.length - 1];
        components.push(<div key='profile-school-logo'>
            <div className="form-group">
                <label htmlFor="schoolLogo">School Logo</label>
                {
                    this.state.isTeacher ? null :
                        <input
                            type="file"
                            onChange={this.onAttachPictureFile}
                            style={{ width: '100%', paddingTop: 7, }}
                            name='schoolLogo'
                            accept='image/png,image/jpeg'
                        />
                }
            </div>
            {
                CheckObjectNullValue(targetProfile, 'LogoUrl') === null ? null :
                    <button type="button" style={{ borderStyle: 'none', backgroundColor: 'transparent' }}
                        onClick={() => window.open(logoUrl, '_blank')}
                        title={logoFileName}>
                        <img src={logoUrl} alt={logoFileName} style={{ maxWidth: 155, maxHeight: 155 }} />
                    </button>
            }</div>);

        //return.
        return (components);
    }
    UpdateOrganizerProfile_ViaApi = async () => {

        if (this.state.isTeacher)
            return null;

        let success = false;
        let errorMessage = '';
        let organizerInfo = null;

        this.setState({
            isLoading: true,
        });
        useAppService.getState().setModal('', 'updating profile...', null, AlertMode.Loading);

        let profile = this.state.TargetProfile;
        profile['Id'] = CheckObjectNumber(profile, 'OrganizerId');

        const { authorId } = GetPropIds(useGlobal.getState().user);
        const jsonModal = JSON.stringify({
            Secret: SecretKey.Common,
            AuthorId: authorId,
            Remove: false,
            Modal: JSON.stringify(profile),
        });
        if (this.state.isDevMode)
            console.log('UpdateOrganizerProfile_ViaApi =\n' + jsonModal);

        const url = GlobalSetting.ApiUrl + 'Api/LearningCentre/Author/Organizer/Update';

        await fetch(url, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: jsonModal,
        })
            .then(res => res.json())
            .then(data => {
                if (this.state.isDevMode)
                    console.log('UpdateOrganizerProfile_ViaApi (source)\n' + JSON.stringify(data));

                success = CheckObjectBoolean(data, 'success');
                if (success) {
                    organizerInfo = CheckObjectNullValue(data, 'data');
                }
                else {
                    if (this.state.isDevMode)
                        console.log('Error', 'api - organizer profile - update (failed)\n' + CheckObjectStringEmpty(data, 'message'));
                }
            })
            .catch(error => {
                errorMessage = error.message;
                if (this.state.isDevMode)
                    console.log('Error', 'api - organizer profile - update (error)\n' + error.message);
            });

        if (success && organizerInfo !== null) {
            organizerInfo = CapitalizeJsonKeys(organizerInfo);
            let user = useGlobal.getState().user;
            let organizerList = CheckObjectNullValue(user, 'OrganizerInfoList', []);
            const findIndex = Array.isArray(organizerList) ? organizerList.findIndex(x => x.OrganizerId === organizerInfo.OrganizerId) : -1;
            if (findIndex > -1) {
                organizerList[findIndex] = organizerInfo;
                user['OrganizerInfoList'] = organizerList;
                user['OrganizerInfo'] = organizerInfo;
                useGlobal.getState().setUser(user);
            }
            this.ToggleEditOrganizerProfileUi();
            // await Delay(300);
            this.setState({
                organizerInfo: organizerInfo,
                organizerList: organizerList,
            });
            // useGlobal.getState().updateOrganizerInfo(organizerInfo);
            await Delay(300);
            this.ToggleEditOrganizerProfileUi();
        }

        this.setState({
            isLoading: false,
        });
        useAppService.getState().setModal();
        await Delay(500);

        if (success)
            useAppService.getState().setModal('', 'Organizer profile has been updated.');
        else
            useAppService.getState().setModal('Operation Failed', 'Failed to update organizer profile.<br />' + errorMessage);
    }
    onAttachPictureFile = (event) => {
        this.setState({
            AttachedPictureFile: event === null ? null : event.target.files[0],
            PictureFileName: event === null ? '' : CheckObjectStringEmpty(this.state.organizerInfo, 'Name') + '-' + CheckObjectStringEmpty(this.state.organizerInfo, 'SchoolCode') + '-' + RandomId(),
        }, async () => {
            //Upload picture file.
            if (this.state.AttachedPictureFile !== null) {
                await this.UploadOrganizerLogo_ViaApi(event === null);
            }
            else {
                this.setState({
                    AttachedPictureFile: null,
                    PictureFileName: '',
                });
            }
        });
    }
    UploadOrganizerLogo_ViaApi = async () => {

        if (this.state.isTeacher)
            return null;

        const obj = this.state.TargetProfile;
        if (obj === null) {
            useAppService.getState().setModal('Upload Failed', 'Invalid organizer profile.');
            return null;
        }

        //Convert Picture to Base64.
        let _base64 = '';
        let _fileConversionError = '';
        if (this.state.AttachedPictureFile !== null) {
            // let reader = new FileReader();
            // reader.onload = (evt) => {
            //     // _base64 = btoa(reader.result);
            //     _base64 = btoa(String.fromCharCode(...new Uint8Array(reader.result)));
            // };
            // reader.readAsArrayBuffer(this.state.AttachedPictureFile);
            try {
                _base64 = await ToBase64(this.state.AttachedPictureFile);
            } catch (error) {
                _fileConversionError = '<br /><br />' + error;
            }
        }
        // await Delay(300);
        if (CheckNullValue(_base64) === null) {
            useAppService.getState().setModal('Upload Failed', 'image file conversion error.' + _fileConversionError);
            return null;
        }

        //FileExt & FileType.
        const _fileName = CheckStringEmpty(this.state.AttachedPictureFile.name).toLowerCase();
        let _fileType = '';
        let _fileExt = '';
        if (_fileName.includes('.jpg')) {
            _fileType = 'image/jpeg';
            _fileExt = '.jpg';
        }
        else if (_fileName.includes('.png')) {
            _fileType = 'image/png';
            _fileExt = '.png';
        }
        this.setState({
            isLoading: true,
        });
        useAppService.getState().setModal('', 'uploading image file...', null, AlertMode.Loading);

        //Upload.
        const { authorId, organizerId } = GetPropIds(useGlobal.getState().user);
        const { success, message, data } = await UploadPictureFileForOrganizer_ViaAPI({
            AuthorId: authorId,
            OrganizerId: organizerId,
            FileExt: _fileExt,
            FileType: _fileType,
            FileName: this.state.PictureFileName,
            Base64: _base64,
        });
        if (!success) {
            useAppService.getState().setModal('', 'Failed to upload image file.<br /><br />Error:' + message);
        }
        else {
            const organizerInfo = CapitalizeJsonKeys(data);
            let user = useGlobal.getState().user;
            let organizerList = CheckObjectNullValue(user, 'OrganizerInfoList', []);
            const findIndex = Array.isArray(organizerList) ? organizerList.findIndex(x => x.OrganizerId === organizerInfo.OrganizerId) : -1;
            if (findIndex > -1) {
                organizerList[findIndex] = organizerInfo;
                user['OrganizerInfoList'] = organizerList;
                user['OrganizerInfo'] = organizerInfo;
                useGlobal.getState().setUser(user);
            }
            this.ToggleEditOrganizerProfileUi();
            // await Delay(300);
            this.setState({
                organizerInfo: organizerInfo,
                organizerList: organizerList,
            });
            // useGlobal.getState().updateOrganizerInfo(organizerInfo);
            await Delay(300);
            this.ToggleEditOrganizerProfileUi();
            useAppService.getState().setModal('', "Organizer's logo has been updated.");
        }
        this.setState({
            isLoading: false,
        });
    }
    //#endregion

    render = () => {
        if (this.state.redirect) {
            return <Redirect to={this.state.redirectLink} />;
        }
        return (<div className="layout-main" id='main-layout'>
            <div className="header">
                <div className="logo">
                    <button className="btn-link" onClick={() => useAppService.getState().logout()} title="logout">
                        <img src={ileLogo} alt="iKEY Learning Evaluation" />
                    </button>
                </div>
                <SplitButton
                    id='main-layout-split-btn-menu'
                    className="btn-dropdown"
                    title={CheckStringEmpty(this.getMenuTitle(this.state.screen), 'Menu')}
                    align={{ lg: 'start' }}
                    menuRole='menu'
                >
                    {
                        Object.keys(Menu).map((keyName, key) => {
                            const data = Menu[keyName];
                            // if (data.ShowInMenu)
                            //     if (data.Show === Access.All
                            //         || (data.Show === Access.AuthorOnly && this.state.isAuthor)
                            //         || (data.Show === Access.AdminOnly && this.state.isAdmin)
                            //         || (data.Show === Access.MasterAdminOnly && this.state.isMasterAdmin)
                            //         || (data.Show === Access.SuperAdminOnly && this.state.isSuperAdmin))
                            if (ShowOrHideMenu(data)) {
                                return <Dropdown.Item
                                    className="btn-dd-item"
                                    key={'k-menu-di-' + key}
                                    eventKey={'ek-menu-di-' + key}
                                    onClick={() => {
                                        if (!data.Disabled) {
                                            this.gotoScreen(data.Label, data.Screen, data.Link);
                                        }
                                    }}
                                    disabled={this.state.screen === data.Screen}
                                    style={data.Disabled ? { userSelect: 'none', cursor: 'not-allowed', textAlign: 'left' } : { textAlign: 'left' }}
                                >{data.Label}</Dropdown.Item>;
                            }
                            return null;
                        })
                    }
                </SplitButton>
                {
                    // Linked organizer(s) list
                    this.state.organizerList.length === 0 ? null :
                        this.state.organizerList.length < 2 ? null :
                            <SplitButton
                                id='main-layout-split-btn-organizers'
                                className="btn-dropdown"
                                title={CheckStringEmpty(this.getOrganizerTitle(this.state.organizerInfo), 'Organizers')}
                                align={{ lg: 'start' }}
                            >
                                {
                                    this.state.organizerList.map((data, key) => {
                                        if (data !== null)
                                            return <Dropdown.Item
                                                className="btn-dd-item"
                                                key={'k-menu-di-o-' + key}
                                                eventKey={'ek-menu-di-o-' + key}
                                                onClick={() => {
                                                    useGlobal.getState().updateOrganizerInfo(data);
                                                    // useGlobal.getState().refreshListCallbackFn();
                                                    setTimeout(() => {
                                                        this.setState({ organizerInfo: data });
                                                        AdjustSectionRightDiv();
                                                    }, 0);
                                                }}
                                                disabled={CheckObjectStringEmpty(this.state.organizerInfo, 'Identity') === data.Identity}
                                            >{data.DisplayName}</Dropdown.Item>;
                                        return null;
                                    })
                                }
                            </SplitButton>
                }
                {/* <span style={{ color: 'white', fontWeight: 'bold' }}>{useGlobal.getState().screen}</span> */}
                <div className="section-right" id='section-right-div'>
                    {
                        CheckObjectNullValue(this.state.organizerInfo, 'LogoUrl') === null ? null :
                            <div className="school-logo">
                                <img src={CheckObjectStringEmpty(this.state.organizerInfo, 'LogoUrl')}
                                    alt={CheckObjectStringEmpty(this.state.organizerInfo, 'DisplayName')}
                                    title={CheckObjectStringEmpty(this.state.organizerInfo, 'DisplayName')}
                                    style={{ maxWidth: 50, maxHeight: 50 }}
                                />
                            </div>
                    }
                    <div className="author" title='Edit Organizer Profile' onClick={() => this.ToggleEditOrganizerProfileUi()}>
                        <span>{CheckStringEmpty(this.state.authorName)}</span>
                        <span>{CheckStringEmpty(this.state.email)}</span>
                        <span>{CheckObjectStringEmpty(this.state.organizerInfo, 'DisplayName')}</span>
                    </div>
                    <Button className="btn-logout" onClick={() => useAppService.getState().logout()}>Logout</Button>
                </div>
            </div>
            <div className="content">{this.props.children}</div>

            {/* Organizer Profile - Edit - Modal */}
            < Modal show={this.state.EditProfileModal_Toggle}
                onHide={() => this.ToggleEditOrganizerProfileUi()}
                centered
                dialogClassName='alert-dialog-bordered'
                size='lg'
            >
                <Modal.Header closeButton={true}>
                    <Modal.Title style={{ fontSize: 20 }}>Organizer Profile :: Edit</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {this.ProfileComponents()}
                </Modal.Body>
                <Modal.Footer>
                    {
                        this.state.isTeacher ? null :
                            <>
                                <Button variant="secondary" onClick={() => this.ResetEditOrganizerProfile()} disabled={this.state.isLoading}>Reset</Button>
                                <Button variant="primary" onClick={() => this.UpdateOrganizerProfile_ViaApi()} disabled={this.state.isLoading}>Save</Button>
                            </>
                    }
                    {/* <Button variant="secondary" onClick={() => this.ResetEditOrganizerProfile()} disabled={this.state.isLoading}>Reset</Button> */}
                    {/* <Button variant="primary" onClick={() => this.UpdateOrganizerProfile_ViaApi()} disabled={this.state.isLoading}>Save</Button> */}
                    <Button variant="secondary" onClick={() => this.ToggleEditOrganizerProfileUi()} disabled={this.state.isLoading}>Cancel</Button>
                </Modal.Footer>
            </Modal >

            <ToastContainer
                className="p-3"
                position={CheckObjectStringEmpty(this.state.Toast_Setting, 'position', ToastPosition.TopEnd)}
                style={{ zIndex: 100, marginTop: 50 }}
            >
                <Toast
                    // ref={this.Ref_Toast}
                    show={this.state.Toast_Toggle}
                    onClose={() => this.toggleToast()}
                    style={{ backgroundColor: '#fff' }}
                // delay={3000}
                // autohide={true}
                >
                    <Toast.Header closeButton={true} className="primary text-white" style={{ backgroundColor: '#0d6efdd9' }}>
                        <strong className="me-auto">{CheckObjectNullValue(this.state.Toast_Setting, 'title')}</strong>
                        <small>{CheckObjectNullValue(this.state.Toast_Setting, 'time')}</small>
                    </Toast.Header>
                    <Toast.Body>{CheckObjectNullValue(this.state.Toast_Setting, 'body')}</Toast.Body>
                </Toast>
            </ToastContainer>
        </div >);
    }
}