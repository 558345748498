import React from 'react';
import { Redirect } from 'react-router-dom';
// import { browserName } from 'react-device-detect';
// import moment from 'moment';
import { Card, Col, Container, Row, Spinner } from 'react-bootstrap';

import { Locale } from '../utilities/localization/CustomLocalization';
// import LocalizationSwitcher from '../utilities/localization/LocalizationSwitcher';
import { CheckBoolean, CheckNullValue, CheckObjectBoolean, CheckObjectStringEmpty, CheckStringEmpty, Delay, DoNothing } from '../utilities/GlobalFunctions';
import { isStaging, LayoutScreen, regex_email } from '../utilities/GlobalSetting';
import { useGlobal } from '../utilities/GlobalVariables';
import { useAppService } from '../services/AppService';
import { AlertMode } from './AlertComponent';

// let removeIframeInterval = null;

export default class LoginScreen extends React.Component {

    constructor(props) {
        super(props);
        this.state = this.getInitState();   //all states will get refresh everytime enter this page.
    }

    getInitState = () => ({
        locale: useGlobal.getState().locale,
        redirect: false,
        redirectLink: '/',
        isLoading: false,

        loginEmail: '',
        loginPassword: '',

        emailValidation: '',
        passwordValidation: '',

        saveLoginCredential: false,
        isValid: false,

    });

    unsubGV = () => { }

    componentWillUnmount = () => {
        this.unsubGV();
        // clearInterval(removeIframeInterval);
    }

    componentDidMount = () => {
        useGlobal.getState().setRefreshListCallbackFn(null);
        useGlobal.getState().setScreen(LayoutScreen.Login);
        this.unsubGV = useGlobal.subscribe((state) => {
            // console.log(JSON.stringify(state));
            if (state.user !== null) {
                this.SaveLoginDetailsInLocal();

                const firstPageToLoad = CheckObjectStringEmpty(state.user, 'FirstPageToLoad');      //2024.09.13

                if (CheckObjectBoolean(state.user, 'ForceResetPassword'))
                    this.setState({ redirect: true, redirectLink: '/organizer/changePassword' });
                // else if (CheckObjectBoolean(state, 'isSuperAdmin'))
                //     this.setState({ redirect: true, redirectLink: '/admin' });
                else if (firstPageToLoad !== '')
                    this.setState({ redirect: true, redirectLink: firstPageToLoad });     //2024.09.13
                else if (CheckObjectBoolean(state, 'isAuthor'))
                    this.setState({ redirect: true, redirectLink: '/dashboard' });
                else
                    this.setState({ redirect: true, redirectLink: '/' });     //back to login.
            }
        });
        this.LoadLoginDetailFromLocal();
        useAppService.getState().setModal();

        // //remove all iframe every 5s.
        // removeIframeInterval = setInterval(() => {
        //     const frames = document.getElementsByTagName("iframe");
        //     for (let i = 0; i < frames.length; ++i) {
        //         frames[i].remove();
        //     }
        // }, 5000);
    }

    setEmail = (event) => {
        this.setState({
            loginEmail: CheckStringEmpty(event.target.value).replace(new RegExp(' ', 'g'), '').toLowerCase(),
            emailValidation: '',
        }, async () => await this.validateInputs());
    }

    setPassword = (event) => {
        this.setState({
            loginPassword: CheckStringEmpty(event.target.value).replace(new RegExp(' ', 'g'), ''),
            passwordValidation: '',
        }, async () => await this.validateInputs());
    }

    validateInputs = async () => {
        let msg = [];

        //Email.
        if (this.state.loginEmail.length === 0) { msg.push(Locale("email-not-empty", this.state.locale)); }
        if (regex_email.test(this.state.loginEmail) === false) { msg.push(Locale("email-bad-format", this.state.locale)); }
        this.setState({ emailValidation: msg.join('<br />') });

        msg = [];

        //Password.
        if (this.state.loginPassword.length === 0) { msg.push(Locale("password-not-empty", this.state.locale)); }
        if (this.state.loginPassword.length < 6) { msg.push(Locale("password-too-short", this.state.locale)); }
        this.setState({ passwordValidation: msg.join('<br />') });

        await Delay(0);
        this.setState({ isValid: this.state.emailValidation === '' && this.state.passwordValidation === '' });

        //save to global variables.
        useGlobal.setState({
            email: this.state.loginEmail,
            password: this.state.loginPassword,
        });
        // useGlobal.getState().setEmail(this.state.loginEmail);
        // useGlobal.getState().setPassword(this.state.loginPassword);

        // console.log('email = ' + useGlobal.getState().email);
        // console.log('password = ' + useGlobal.getState().password);
        // console.log('emailValidation = ' + this.state.emailValidation);
        // console.log('passwordValidation = ' + this.state.passwordValidation);
    }

    toggleSavePassword = () => {
        this.setState({ saveLoginCredential: !this.state.saveLoginCredential });
    }

    handleLogin = async () => {

        this.setState({ isLoading: true });
        // if (useGlobal.getState().isInternetReachable === false) {
        //     this.props.SetAlert(
        //         Locale("label-internet-not-avai", this.state.locale),
        //         Locale("text-internet-not-avai", this.state.locale)
        //     );
        //     this.setState({ isLoading: false });
        //     return null;
        // }

        useAppService.getState().setModal(Locale("logging-in", this.state.locale), Locale("please-wait", this.state.locale), null, AlertMode.Loading);

        const { success, messages } = await useAppService.getState().login(this.state.loginEmail, this.state.loginPassword, this.state.saveLoginCredential);
        await Delay(0);
        if (!success) {
            this.setState({ isLoading: false });
            useAppService.getState().setModal(Locale("error", this.state.locale), messages.join('<br />'));
        }
        else {
            useAppService.getState().setModal();
        }
    }

    LoadLoginDetailFromLocal = async () => {
        if (typeof (Storage) !== 'undefined') {
            const saveLogin = CheckBoolean(localStorage.getItem('saveLoginCredential'));
            this.setState({
                saveLoginCredential: saveLogin,
            }, () => {
                if (this.state.saveLoginCredential) {
                    const email = CheckStringEmpty(localStorage.getItem('email')).replace(new RegExp(' ', 'g'), '').toLowerCase();
                    const password = CheckStringEmpty(localStorage.getItem('password')).replace(new RegExp(' ', 'g'), '');
                    // alert(email + "\n" + password);
                    this.setState({
                        loginEmail: email,
                        loginPassword: password,
                    }, () => {
                        //save to global variables.
                        useGlobal.setState({
                            email: this.state.loginEmail,
                            password: this.state.loginPassword,
                        });
                        // this.props.setEmailPassword(email, password);
                    });
                }
                else {
                    //save to global variables.
                    useGlobal.setState({
                        email: '',
                        password: '',
                    });
                    // this.props.setEmailPassword('', '');
                }
            });
        } else {
            // Sorry! No Web Storage support..      
        }
        await Delay(0);
        await this.validateInputs();
    }

    SaveLoginDetailsInLocal = () => {
        if (typeof (Storage) !== 'undefined') {
            // code for localStorage/sessionStorage.
            localStorage.setItem('saveLoginCredential', this.state.saveLoginCredential);
            if (this.state.saveLoginCredential) {
                if (CheckStringEmpty(this.state.loginEmail).length > 0)
                    localStorage.setItem('email', this.state.loginEmail);
                if (CheckStringEmpty(this.state.loginPassword).length > 0)
                    localStorage.setItem('password', this.state.loginPassword);
            }
            else {
                localStorage.setItem('email', '');
                localStorage.setItem('password', '');
            }
        } else {
            // Sorry! No Web Storage support..
        }
    }

    render = () => {
        if (this.state.redirect) {
            return <Redirect to={this.state.redirectLink} />;
        }
        return (<Container style={{ marginTop: 25, width: 550, maxWidth: 550 }}>
            <Row>
                <Col>
                    <p style={{
                        color: 'lavender', fontSize: 25, textAlign: 'center', fontWeight: 'bold',
                        textShadow: '2px 1px 1px red'
                    }}>iKEY Learning Evaluation :: Portal</p>

                    <div style={{
                        width: 550,
                        textAlign: 'center',
                        verticalAlign: 'bottom',
                        display: 'table-cell',
                        paddingBottom: 5,
                    }}>
                        <span style={{
                            color: 'white',
                            // backgroundColor: 'black',
                            // opacity: 0.2,
                            fontWeight: 500,
                            textShadow: '2px 1px 1px #000000'
                        }}>{Locale("label-already-have-account-notice", this.state.locale)}</span>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Card style={{}}>
                        {/* <div style={{ opacity: 0.5, backgroundColor: 'lavender', position: 'absolute', width: '100%', height: '100%' }} /> */}
                        <div className="card-body">
                            <button
                                type="button"
                                className="float-end btn btn-outline-primary"
                                onClick={() => this.GotoSignUp()}
                                hidden
                            >{Locale("signup-register", this.state.locale)}</button>
                            <h4 className="card-title mb-4 mt-1"><b>{Locale("login", this.state.locale)}</b></h4>
                            <form>
                                <div className="form-group">
                                    <label>{Locale("your-email", this.state.locale)}</label>
                                    <input name="username"
                                        className="form-control"
                                        // placeholder={Locale("email-address", this.state.locale)}
                                        placeholder={CheckStringEmpty(this.state.loginEmail, 'enter email address here')}
                                        type="email"
                                        autoComplete='username'
                                        onKeyUp={this.setEmail}
                                        onBlur={this.setEmail}
                                        defaultValue={CheckStringEmpty(this.state.loginEmail)}
                                        disabled={this.state.isLoading}
                                    />
                                    <div className='error-validation'
                                        hidden={CheckStringEmpty(this.state.emailValidation) === ''}
                                        dangerouslySetInnerHTML={{ __html: '<ul><li>' + CheckStringEmpty(this.state.emailValidation, null).replaceAll('<br />', '</li><li>') + '</li></ul>' }}></div>
                                </div>

                                <div className="form-group">
                                    <a className="float-end" href="/passwordReset" hidden>{Locale("forgot-password", this.state.locale)}</a>
                                    <label>{Locale("your-password", this.state.locale)}</label>
                                    <input
                                        className="form-control"
                                        placeholder={CheckNullValue(this.state.loginPassword) === null ? 'enter password here' : '******'}
                                        type="text"
                                        autoComplete='current-password'
                                        onKeyUp={this.setPassword}
                                        onBlur={this.setPassword}
                                        disabled={this.state.isLoading}
                                    />
                                    <div className='error-validation'
                                        hidden={CheckStringEmpty(this.state.passwordValidation) === ''}
                                        dangerouslySetInnerHTML={{ __html: '<ul><li>' + CheckStringEmpty(this.state.passwordValidation, null).replaceAll('<br />', '</li><li>') + '</li></ul>' }}></div>
                                </div>

                                <div className="form-group">
                                    <div className="checkbox" onClick={() => this.state.isLoading ? DoNothing() : this.toggleSavePassword()}>
                                        <input
                                            type="checkbox"
                                            checked={this.state.saveLoginCredential}
                                            readOnly
                                        />
                                        <label>{Locale("keep-email-password", this.state.locale)}</label>
                                    </div>
                                </div>

                                <div className="form-group">
                                    <button
                                        type="button"
                                        className="btn btn-primary btn-block float-end"
                                        onClick={() => this.handleLogin()}
                                        style={{ width: 145 }}
                                        // disabled={this.state.emailValidation !== '' || this.state.passwordValidation !== ''}
                                        disabled={!this.state.isValid || this.state.isLoading}
                                    >{this.state.isLoading ? <Spinner animation='border' size='sm'></Spinner> : Locale("login", this.state.locale)}</button>
                                </div>
                            </form>
                        </div>

                    </Card>
                </Col>
            </Row>
            <Row>
                <Col style={{ textAlign: 'center', color: 'lightgray', marginTop: 5, fontWeight: isStaging ? 'bold' : 'initial' }}
                >{`2024.11.15 ${isStaging ? ' === STAGING ===' : ''}`}</Col>
            </Row>
        </Container>);
    }
}