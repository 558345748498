import { create } from 'zustand';
import { DefaultCustomPermissionModel_Restricted, GlobalSetting, AdminRolePermissions, SuperAdminRolePermissions, TeacherRolePermissions, ViewerRolePermissions, BasicRolePermissions, Menu } from '../utilities/GlobalSetting';
import { CheckBoolean, CheckNullValue, CheckObjectBoolean, CheckObjectNullValue, CheckObjectNumber, CheckObjectStringEmpty, CheckStringEmpty, Delay, GetPropIds } from '../utilities/GlobalFunctions';
import { UserLoginResult } from '../utilities/UserLoginResult';
import { auth, signInWithCustomToken, signOut } from '../utilities/Firebase';
import { useGlobal } from '../utilities/GlobalVariables';
import { Locale } from '../utilities/localization/CustomLocalization';

const isDevMode = window.location.href.includes('localhost');

export const useAppService = create((set) => ({

    toggleToast: () => { },
    setToggleToast: (toggleToast) => set({ toggleToast }),

    login: async (email, password, rememberMe) => {

        let success = false;
        let response = null;
        let responseData = null;
        let messages = [];
        let credential = null;
        let user = null;

        const postData = JSON.stringify({
            Email: String(email),
            Password: String(password),
            RememberMe: CheckBoolean(rememberMe),
        });

        await fetch(GlobalSetting.ApiUrl + 'Api/LearningCentre/User/Author/Login/V2',    //'Api/LearningCentre/Account/Login',
            {
                method: 'POST',                             // *GET, POST, PUT, DELETE, etc.
                // mode: 'cors',                            // no-cors, *cors, same-origin
                // cache: 'no-cache',                       // *default, no-cache, reload, force-cache, only-if-cached
                // credentials: 'omit',                     // include, *same-origin, omit
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                // redirect: 'follow',                      // manual, *follow, error
                // referrerPolicy: 'no-referrer',           // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
                body: postData,                             // body data type must match "Content-Type" header
            })
            .then(res => res.json())
            .then((data) => {
                if (isDevMode)
                    console.log('api login response =\n' + JSON.stringify(data));
                response = data;
                success = CheckObjectBoolean(data, 'success');
                if (success)
                    responseData = JSON.parse(CheckObjectStringEmpty(data, 'message'));
                else
                    responseData = CheckObjectNullValue(data, 'message');
                // success = true;
            })
            .catch(error => {
                messages.push('api login failed. | ' + String(error.message));
                if (isDevMode)
                    console.log('api login failed.\n' + String(error.message));
            });

        if (responseData != null) {
            if (CheckObjectBoolean(response, 'success')) {
                //success.
                const isUserNotExist = CheckObjectNumber(response, 'code') === UserLoginResult.UserNotExist;
                if (isUserNotExist) {
                    messages.push("isUserNotExist: " + isUserNotExist + " | " + JSON.stringify(responseData));
                }
                else {
                    //Custom Token Login via Firebase.
                    const firebaseToken = CheckObjectStringEmpty(responseData, 'FirebaseToken');
                    if (firebaseToken.length > 0) {
                        //sign in.
                        await signInWithCustomToken(auth, firebaseToken)
                            .then((userCredential) => {
                                credential = JSON.parse(JSON.stringify(userCredential));
                                user = credential.user;
                            })
                            .catch((error) => {
                                const locale = useGlobal.getState().locale;
                                // const title = Locale("error", locale);
                                if (error.code === 'auth/user-not-found')
                                    messages.push(Locale("notice-login-user-not-found", locale));
                                else if (error.code === 'auth/wrong-password')
                                    messages.push(Locale("notice-login-wrong-password", locale));
                                else
                                    messages.push(error.message);
                            });
                        //sign in success.
                        if (credential !== null) {
                            success = true;
                            if (isDevMode) {
                                console.log('credential: \n' + JSON.stringify(credential));
                                console.log('user: \n' + JSON.stringify(user));
                            }
                            await PopulateUserIdentity(responseData, credential, user);
                        }
                        //done.
                    }
                }
            }
            else {
                //failed.
                messages.push(responseData);
            }
        }
        if (isDevMode)
            console.log('login (success) ' + success);

        return { success, messages, responseData, credential, user };
    },

    logout: async () => {
        let success = false;
        let messages = [];
        await signOut(auth)
            .then(() => {
                success = true;
                useGlobal.setState({
                    user: null,
                    credential: null,
                });
                window.location.reload();
            })
            .catch((error) => {
                messages.push(error.message);
            });
        return { success, messages };
    },

    organizerList: [],
    setOrganizerList: organizerList => set({ organizerList }),
    // getOrganizerList: async () => {

    // },

    groups: [],
    setGroups: groups => set({ groups }),
    getGroups: async (refresh = false) => {
        let groups = useAppService.getState().groups;
        if (refresh || groups === null || groups === undefined)
            groups = [];
        if (groups.length === 0) {
            const user = useGlobal.getState().user;
            if (user !== null) {
                const { authorId, organizerId } = GetPropIds(user);
                await fetch(GlobalSetting.ApiUrl + 'Api/LearningCentre/Quiz/Group/List/'
                    + organizerId + '/'
                    + authorId + '/'
                    + 0 + '/'
                    + 99999,
                    // Api/LearningCentre/Quiz/Group/List/{organizerId}/{authorId}/{pageIndex}/{pageSize}
                    {
                        method: 'GET',
                        headers: {
                            'Accept': 'application/json',
                            // 'Content-Type': 'application/json',
                        },
                    })
                    .then(res => res.json())
                    .then(data => {
                        // if (isDevMode)
                        //     console.log('getGroups =\n', JSON.stringify(data));
                        if (data.success)
                            groups = data.data;
                        else
                            if (isDevMode)
                                console.log(`getGroups (failed) \n ${JSON.stringify(data)}`);
                    })
                    .catch(error => {
                        if (isDevMode)
                            console.log(`getGroups (error) \n ${error.message}`);
                    });
                // groups = CapitalizeJsonKeys(groups);
                useAppService.getState().setGroups(groups);
                if (isDevMode)
                    console.log('groups (getGroups) \n' + JSON.stringify(groups));
                await Populate_GroupOptions();
                await Delay(0);
            }
        }
        return groups;
    },
    groupOptions: [],
    setGroupOptions: groupOptions => set({ groupOptions }),

    subjects: [],
    setSubjects: subjects => set({ subjects }),
    getSubjects: async (refresh = false) => {
        let subjects = useAppService.getState().subjects;
        if (refresh || subjects === null || subjects === undefined)
            subjects = [];
        if (subjects.length === 0) {
            const user = useGlobal.getState().user;
            if (user !== null) {
                const { authorId, organizerId } = GetPropIds(user);
                await fetch(GlobalSetting.ApiUrl + 'Api/LearningCentre/Quiz/Subject/List/'
                    + organizerId + '/'
                    + authorId + '/'
                    + 0 + '/'
                    + 99999,
                    // Api/LearningCentre/Quiz/Group/List/{organizerId}/{authorId}/{pageIndex}/{pageSize}
                    {
                        method: 'GET',
                        headers: {
                            'Accept': 'application/json',
                            // 'Content-Type': 'application/json',
                        },
                    })
                    .then(res => res.json())
                    .then(data => {
                        // if (isDevMode)
                        //     console.log('getSubjects =\n', JSON.stringify(data));
                        if (data.success)
                            subjects = data.data;
                        else
                            if (isDevMode)
                                console.log(`getSubjects (failed) \n ${JSON.stringify(data)}`);
                    })
                    .catch(error => {
                        if (isDevMode)
                            console.log(`getSubjects (error) \n ${error.message}`);
                    });
                // subjects = CapitalizeJsonKeys(subjects);
                useAppService.getState().setSubjects(subjects);
                await Delay(0);
                if (isDevMode)
                    console.log('subjects (getSubjects) \n' + JSON.stringify(subjects));
                await Populate_SubjectOptions();
                await Delay(0);
            }
        }
        return subjects;
    },
    subjectOptions: [],
    setSubjectOptions: subjectOptions => set({ subjectOptions }),

    alertRefMaxQty: 10,
    alertLevelIndex: -1,
    setAlertLevelIndex: alertLevelIndex => set({ alertLevelIndex }),
    increaseAlertLevelIndex: () => {
        const alertRefMaxQty = Number(useAppService.getState().alertRefMaxQty);
        let alertLevelIndex = Number(useAppService.getState().alertLevelIndex) + 1;
        if (alertLevelIndex < alertRefMaxQty)
            set({ alertLevelIndex: alertLevelIndex });
        return useAppService.getState();
    },
    decreaseAlertLevelIndex: () => {
        let alertLevelIndex = Number(useAppService.getState().alertLevelIndex) - 1;
        if (alertLevelIndex < 0)
            alertLevelIndex = 0;
        set({ alertLevelIndex: alertLevelIndex });
        return useAppService.getState();
    },
    closeModal: () => { },
    setModal: () => { },
    setLoading: () => { },
    closeAlerts: () => { },
    setAlerts: () => { },
    setLoadings: () => { },

    questionSetUniqueId: '',
    setQuestionSetUniqueId: questionSetUniqueId => set({ questionSetUniqueId }),

    questionNoList: [],
    setQuestionNoList: questionNoList => set({ questionNoList }),
    selectedQuestionIndex: -1,
    setSelectedQuestionIndex: selectedQuestionIndex => set({ selectedQuestionIndex }),
    updateQuestionNoList: (list = null, index = -1) => {
        useAppService.getState().setQuestionNoList(list);
        useAppService.getState().setSelectedQuestionIndex(index);
    },

    //2024.07.16
    classrooms: [],
    setClassrooms: classrooms => set({ classrooms }),
    getClassrooms: async (refresh = false, fullList = true) => {
        let success = false;
        let message = '';
        let classrooms = useAppService.getState().classrooms;
        if (refresh || Array.isArray(classrooms) === false || classrooms === null || classrooms === undefined)
            classrooms = [];
        if (classrooms.length === 0) {
            const user = useGlobal.getState().user;
            if (user !== null) {
                const { authorId, organizerId } = GetPropIds(user);
                if (isDevMode)
                    console.log(`getClassrooms (fetch) ${organizerId} ${authorId}`);
                await fetch(GlobalSetting.ApiUrl + 'Api/LearningCentre/Classroom/List/' + organizerId + '/' + authorId + '/' + String(fullList).toLowerCase(),
                    // Api/LearningCentre/Classroom/List/{organizerId}/{authorId}
                    {
                        method: 'GET',
                        headers: {
                            'Accept': 'application/json',
                            // 'Content-Type': 'application/json',
                        },
                    })
                    .then(res => res.json())
                    .then(data => {
                        if (isDevMode)
                            console.log(`getClassrooms (response) \n ${JSON.stringify(data)}`);

                        success = CheckObjectBoolean(data, 'success');
                        if (success)
                            classrooms = data.data;
                        else
                            message = CheckObjectStringEmpty(data, 'message');
                    })
                    .catch(error => {
                        message = CheckObjectStringEmpty(error, 'message');
                        if (isDevMode)
                            console.log(`getClassrooms (error) \n ${message}`);
                    });
                useAppService.getState().setClassrooms(classrooms);
                if (isDevMode)
                    console.log('classrooms (getClassrooms) \n' + JSON.stringify(classrooms));
                await Populate_ClassroomOptions();
                await Delay(0);
            }
        }
        return { success, message, data: classrooms };
    },
    classroomOptions: [],
    setClassroomOptions: classroomOptions => set({ classroomOptions }),

    //2024.07.16
    dialingCodes: [],
    setDialingCodes: dialingCodes => set({ dialingCodes }),
    getDialingCodes: async (refresh = false) => {
        let success = false;
        let message = '';
        let dialingCodes = useAppService.getState().dialingCodes;
        if (refresh || Array.isArray(dialingCodes) === false || dialingCodes === null || dialingCodes === undefined)
            dialingCodes = [];
        if (dialingCodes.length === 0) {
            const user = useGlobal.getState().user;
            if (user !== null) {
                await fetch(GlobalSetting.ApiUrl + 'Api/LearningCentre/CountryDialingCode/List',
                    // Api/LearningCentre/CountryDialingCode/List
                    {
                        method: 'GET',
                        headers: {
                            'Accept': 'application/json',
                            // 'Content-Type': 'application/json',
                        },
                    })
                    .then(res => res.json())
                    .then(data => {
                        // if (isDevMode)
                        //     console.log(`getDialingCodes (response) \n ${JSON.stringify(data)}`);

                        success = CheckObjectBoolean(data, 'success');
                        if (success)
                            dialingCodes = data.data;
                        else
                            message = CheckObjectStringEmpty(data, 'message');
                    })
                    .catch(error => {
                        message = CheckObjectStringEmpty(error, 'message');
                        if (isDevMode)
                            console.log(`getDialingCodes (error) \n ${message}`);
                    });
                useAppService.getState().setDialingCodes(dialingCodes);
                // if (isDevMode)
                //     console.log('dialingCodes (getDialingCodes) \n' + JSON.stringify(dialingCodes));
                await Populate_DialingCodeOptions();
                await Delay(0);
            }
        }
        return { success, message, data: dialingCodes };
    },
    dialingCodeOptions: [],
    setDialingCodeOptions: dialingCodeOptions => set({ dialingCodeOptions }),


}));

const PopulateUserIdentity = async (responseData = null, credential = null, user = null) => {
    if (!responseData || !credential || !user)
        return null;

    // user = { ...user };

    //Update author credential on iKEY Base.
    await UpdateAuthorCredential(responseData, user);

    //populate user data.
    user['IsOrganizer'] = CheckObjectBoolean(responseData, 'IsOrganizer');
    user['OrganizerInfoList'] = CheckObjectNullValue(responseData, 'OrganizerInfoList') === null ? [] : responseData.OrganizerInfoList;   //null or list of organizer info.
    user['OrganizerInfo'] = Array.isArray(user.OrganizerInfoList) && user.OrganizerInfoList.length > 0 ? user.OrganizerInfoList[0] : null;
    user['IsAdmin'] = CheckObjectBoolean(responseData, 'IsAdmin');
    user['IsViewer'] = CheckObjectBoolean(responseData, 'IsViewer');
    user['ForceResetPassword'] = CheckObjectBoolean(responseData, 'ForceResetPassword');
    user['CustomPermissions'] = CheckCustomPermissions(CheckObjectNullValue(responseData, 'CustomPermissions') === null ? null : JSON.parse(CheckObjectStringEmpty(responseData, 'CustomPermissions')));
    user['AuthorId'] = CheckObjectNumber(responseData, 'AuthorId');
    user['AuthorName'] = CheckObjectStringEmpty(responseData, 'AuthorName');
    user['UserId'] = CheckObjectNumber(responseData, 'UserId');
    user['CenterUserId'] = CheckObjectNumber(responseData, 'CenterUserId')
    user['AuthorRoleId'] = CheckObjectNumber(responseData, 'AuthorRoleId')
    user['IsActive'] = CheckObjectBoolean(responseData, 'IsActive')
    user['FirebaseCustomToken'] = CheckObjectStringEmpty(responseData, 'FirebaseToken');
    user['FirebaseUserId'] = CheckObjectStringEmpty(user, 'uid');
    user['FirebaseAccessToken'] = CheckObjectNullValue(user.stsTokenManager, 'accessToken');
    user['FirebaseRefreshToken'] = CheckObjectNullValue(user.stsTokenManager, 'refreshToken');
    user['FirebaseLastLoginTime'] = CheckObjectNumber(user, 'lastLoginAt');      //epoch timestamp with ms, login time.
    user['FirebaseExpirationTime'] = CheckObjectNumber(user.stsTokenManager, 'expirationTime');    //epoch timestamp with ms, est. 1hr frm login.
    user['IsTeacher'] = CheckObjectBoolean(responseData, 'IsTeacher');    //2024.07.19
    user['IsAllowResetOnRoomResult'] = CheckObjectBoolean(responseData, 'IsAllowResetOnRoomResult');    //2024.07.19

    //2024.09.13
    user['HideDashboard'] = CheckObjectStringEmpty(responseData, 'HideDashboard');
    const firstPageToLoad = CheckObjectStringEmpty(responseData, 'FirstPageToLoad');
    if (firstPageToLoad !== '') {
        Object.keys(Menu).map((data, key) => {
            if (Menu[data].Link === firstPageToLoad) {
                useGlobal.getState().setMenuLabel(Menu[data].Label);
                useGlobal.getState().setScreen(Menu[data].Screen);
            }
            return null;
        });
    }
    user['FirstPageToLoad'] = CheckStringEmpty(firstPageToLoad);

    //admin or author related.
    const superAdminRoles = [1, 9];    //1 = "Administrators", 9 = "iKey Administrator"
    const isSuperAdmin = superAdminRoles.findIndex(x => x === CheckObjectNumber(user, 'AuthorRoleId')) > -1
        || CheckObjectStringEmpty(user, 'FirebaseUserId') === '460db00a-1203-4562-840c-18de74d85355'
        || CheckObjectStringEmpty(user, 'UserId') === '28468588'
        || CheckObjectBoolean(user, 'IsSuperAdmin');
    if (isSuperAdmin) {
        user['IsSuperAdmin'] = true;
        user['CustomPermissions'] = SuperAdminRolePermissions;
    }
    else {
        // if (typeof user['CustomPermissions'] !== 'object') {
        //     if (CheckObjectBoolean(user, 'IsViewer'))
        //         user['CustomPermissions'] = ViewerRolePermissions;
        //     else if (CheckObjectBoolean(user, 'IsTeacher'))
        //         user['CustomPermissions'] = TeacherRolePermissions;
        //     else if (CheckObjectBoolean(user, 'IsAdmin'))
        //         user['CustomPermissions'] = AdminRolePermissions;
        // }
        // else {

        //     //2024.07.23
        //     if (CheckObjectBoolean(user, 'IsTeacher')) {
        //         if (typeof user['OrganizerInfo']['CustomPermissions'] === 'string')
        //             user['CustomPermissions'] = JSON.parse(user['OrganizerInfo']['CustomPermissions']);
        //     }
        //     else {
        //         user['CustomPermissions'] = CheckCustomPermissions(user['CustomPermissions']);
        //     }
        // }

        // console.log(`user['OrganizerInfo']['CustomPermissions'] (typeof) ${typeof user['OrganizerInfo']['CustomPermissions']}`);
        // console.log(`user['OrganizerInfo']['CustomPermissions'] (raw) \n ${user['OrganizerInfo']['CustomPermissions']}`);
        // console.log(`user['OrganizerInfo']['CustomPermissions'] (parse) \n ${JSON.stringify(JSON.parse(user['OrganizerInfo']['CustomPermissions']))}`);

        //2024.07.23
        const organizerInfo = user['OrganizerInfo'];
        if (organizerInfo !== null && CheckObjectStringEmpty(organizerInfo, 'CustomPermissions') !== '') {
            user['CustomPermissions'] = JSON.parse(organizerInfo['CustomPermissions']);
        }
        else {
            if (user['CustomPermissions'] === null || typeof user['CustomPermissions'] !== 'object') {
                if (CheckObjectBoolean(user, 'IsTeacher'))
                    user['CustomPermissions'] = TeacherRolePermissions;
                else if (CheckObjectBoolean(user, 'IsAdmin'))
                    user['CustomPermissions'] = AdminRolePermissions;
                else if (CheckObjectBoolean(user, 'IsViewer'))
                    user['CustomPermissions'] = ViewerRolePermissions;
            }
        }
    }
    // Id	  Name
    // 1*	  Administrators
    // 2	  Registered
    // 3	  Guests
    // 4*	  Center
    // 5*	  Teacher
    // 6	  Parent
    // 7*	  Headquarter
    // 8*	  Center Staff
    // 9*	  iKey Administrator
    // 10	  Dragonfly Headquarter
    // 11*	  Learning Centre Author
    // 12	  Toy Eight
    const authorRoles = [1, 4, 5, 7, 8, 9, 11];
    const findAuthorIndex = authorRoles.findIndex(x => x === Number(user.AuthorRoleId));
    const isAuthor = findAuthorIndex > -1;

    if (isDevMode) {
        // console.log(`PopulateUserIdentity \n isAuthor = ${isAuthor} \n isSA = ${isSuperAdmin} \n user =\n ${JSON.stringify(user)} \n credential =\n ${JSON.stringify(credential)}`);
        console.log(`PopulateUserIdentity \n isAuthor = ${isAuthor} \n isSA = ${isSuperAdmin}`);
        console.log(`PopulateUserIdentity (user) \n${JSON.stringify(user)}`);
        console.log(`PopulateUserIdentity (credential) \n${JSON.stringify(credential)}`);
        console.log(`PopulateUserIdentity (CustomPermissions) \n${JSON.stringify(user['CustomPermissions'])}`);
    }

    //save.
    useGlobal.setState({
        user: user,
        authorName: CheckObjectStringEmpty(user, 'AuthorName'),
        credential: credential,
        isAuthor: isAuthor,
        isTeacher: CheckObjectBoolean(user, 'IsTeacher'),
        isAdmin: CheckObjectBoolean(user, 'IsAdmin'),
        isMasterAdmin: CheckObjectBoolean(user, 'IsMasterAdmin'),
        isSuperAdmin: isSuperAdmin,
        organizerInfo: user['OrganizerInfo'],
    });
    await Delay(200);
}

const UpdateAuthorCredential = async (responseData, user) => {
    //Update data on iKEY Base.

    let success = false;
    let message = '';

    const postData = {
        AuthorId: CheckObjectStringEmpty(responseData, 'AuthorId'),
        UserId: CheckObjectStringEmpty(responseData, 'UserId'),
        CenterUserId: CheckObjectStringEmpty(responseData, 'CenterUserId'),
        AuthorRoleId: CheckObjectStringEmpty(responseData, 'AuthorRoleId'),
        IsActive: CheckObjectStringEmpty(responseData, 'IsActive'),
        FirebaseCustomToken: CheckObjectStringEmpty(responseData, 'FirebaseToken'),

        FirebaseUserId: CheckObjectStringEmpty(user, 'uid'),
        FirebaseAccessToken: CheckObjectStringEmpty(user.stsTokenManager, 'accessToken'),
        FirebaseRefreshToken: CheckObjectStringEmpty(user.stsTokenManager, 'refreshToken'),
        FirebaseLastLoginTime: CheckObjectNumber(user, 'lastLoginAt'),                        //epoch timestamp with ms, login time.
        FirebaseExpirationTime: CheckObjectNumber(user.stsTokenManager, 'expirationTime'),    //epoch timestamp with ms, est. 1hr frm login.
    };
    if (isDevMode)
        console.log('UpdateAuthorCredential \n postData: \n' + JSON.stringify(postData));

    await fetch(GlobalSetting.ApiUrl + 'Api/LearningCentre/User/Author/Update/AuthorCredential',
        {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(postData),
        })
        .then(response => response.json())
        .then(data => {
            success = CheckObjectBoolean(data, 'success');
            if (!success) {
                message = CheckObjectStringEmpty(data, 'message');
                if (isDevMode)
                    console.log('Cred Update Failed', 'api update user cre resp.<br /><br />' + message);
            }
            else {
                if (isDevMode)
                    console.log('Cred Update done.');
            }
        })
        .catch((error) => {
            message = CheckObjectStringEmpty(error, 'message');
            if (isDevMode)
                console.log('Cred Update Failed (Error)', 'api update user cre catch.<br /><br />' + message);
        });

    return { success, message, postData };
}

//Jay revamped 2024.07.22
//Jay revamped 2024.04.05
const CheckCustomPermissions = (customPermissions = null) => {
    if (customPermissions !== null && typeof customPermissions === 'object') {

        let updatedCustomPermissions = {};
        Object.keys(BasicRolePermissions).map((section, skey) => {
            const findIndex_section = Object.keys(customPermissions).findIndex(x => String(x) === String(section));
            if (findIndex_section > -1) {
                let actions = {};
                Object.keys(BasicRolePermissions[section]).map((action, akey) => {
                    const findIndex_action = Object.keys(customPermissions[section]).findIndex(x => x === action);
                    if (findIndex_action > -1)
                        actions[action] = CheckBoolean(customPermissions[section][action]);
                    else
                        actions[action] = false;
                    return null;
                });
                updatedCustomPermissions[section] = actions;
            }
            else {
                updatedCustomPermissions[section] = DefaultCustomPermissionModel_Restricted;
            }
            return null;
        });
        return updatedCustomPermissions;

        //#region older code - before 2024.07.22

        // let final = [];
        // const CheckPermission = (final = [], keyword = '', permission = null) => {
        //     let findIndex_Final = -1;
        //     for (let f = 0; f < final.length; f++) {
        //         const keys = Object.keys(final[f]);
        //         findIndex_Final = keys.findIndex(x => x === keyword);
        //     }
        //     let findIndex_CP = -1;
        //     for (let c = 0; c < customPermissions.length; c++) {
        //         const keys = Object.keys(customPermissions[c]);
        //         findIndex_CP = keys.findIndex(x => x === keyword);
        //         if (findIndex_CP > -1) {
        //             findIndex_CP = c;
        //             break;
        //         }
        //     }
        //     //not found in Final, found in CP.
        //     if (findIndex_Final < 0 && findIndex_CP > -1) {
        //         let tmp = {};
        //         tmp[keyword] = customPermissions[findIndex_CP][keyword];
        //         final.push(tmp);

        //         if (isDevMode)
        //             console.log(keyword + ' = found in CP.');
        //     }
        //     //not found in both sides.
        //     if (findIndex_Final < 0 && findIndex_CP < 0) {
        //         let tmp = {};
        //         tmp[keyword] = permission;
        //         final.push(tmp);

        //         if (isDevMode)
        //             console.log(keyword + ' = not found in both sides.');
        //     }
        //     return final;
        // }

        // //default allow.
        // const defaultAllow = [
        //     'manageRoom', 'manageQuestionSet', 'liveQuizRankingList',
        //     'manageStudentProfile',
        //     // 'manageStudentReport',
        //     'manageSetting', 'manageCustomGroup', 'manageEducationStage'
        // ];
        // defaultAllow.forEach(keyword => {
        //     final = CheckPermission(final, keyword, DefaultCustomPermissionModel_Allowed);
        // });

        // //default restricted.
        // const defaultRestricted = ['manageEvent', 'eventParticipationReport', 'manageAuthor', 'manageOrganizer'];
        // defaultRestricted.forEach(keyword => {
        //     final = CheckPermission(final, keyword, DefaultCustomPermissionModel_Restricted);
        // });

        // if (isDevMode)
        //     console.log('CheckCustomPermissions =\n' + JSON.stringify(final));

        // return final;

        //#region older code.
        // //default allowed.
        // if ('manageRoom' in customPermissions === false)
        //     customPermissions.push({ manageRoom: DefaultCustomPermissionModel_Allowed });
        // if ('manageQuestionSet' in customPermissions === false)
        //     customPermissions.push({ manageQuestionSet: DefaultCustomPermissionModel_Allowed });
        // if ('liveQuizRankingList' in customPermissions === false)
        //     customPermissions.push({ liveQuizRankingList: DefaultCustomPermissionModel_Allowed });
        // if ('manageStudentProfile' in customPermissions === false)
        //     customPermissions.push({ manageStudentProfile: DefaultCustomPermissionModel_Allowed });
        // if ('manageCustomGroup' in customPermissions === false)
        //     customPermissions.push({ manageCustomGroup: DefaultCustomPermissionModel_Allowed });
        // if ('manageStudentReport' in customPermissions === false)
        //     customPermissions.push({ manageStudentReport: DefaultCustomPermissionModel_Allowed });

        // //default restricted.
        // if ('manageEvent' in customPermissions === false)
        //     customPermissions.push({ manageEvent: DefaultCustomPermissionModel_Restricted });
        // if ('eventParticipationReport' in customPermissions === false)
        //     customPermissions.push({ eventParticipationReport: DefaultCustomPermissionModel_Restricted });
        // if ('manageAuthor' in customPermissions === false)
        //     customPermissions.push({ manageAuthor: DefaultCustomPermissionModel_Restricted });
        // if ('manageOrganizer' in customPermissions === false)
        //     customPermissions.push({ manageOrganizer: DefaultCustomPermissionModel_Restricted });
        //#endregion

        //#endregion
    }
    return customPermissions;
}

const Populate_GroupOptions = async () => {
    const items = useAppService.getState().groups;
    if (Array.isArray(items) && items.length > 0) {
        let options = [];
        items.map((data, key) => {
            // let _remark = (data.Remark !== undefined ? data.Remark !== '' ? ' ' + data.Remark : '' : '');
            // return options.push({ value: data.Name, label: data.Name + _remark, id: data.Id });
            const _remark = CheckObjectStringEmpty(data, 'Remark');
            const _name = CheckObjectStringEmpty(data, 'Name');
            const _id = CheckObjectNumber(data, 'Id');
            return options.push({ value: _name, label: _name + ' ' + _remark, id: _id });
        });
        if (isDevMode)
            console.log('Populate_GroupOptions', JSON.stringify(options));
        useAppService.getState().setGroupOptions(options);
        await Delay(0);
    }
}

const Populate_SubjectOptions = async () => {
    const items = useAppService.getState().subjects;
    if (Array.isArray(items) && items.length > 0) {
        let options = [];
        items.map((data, key) => {
            const _display = CheckObjectBoolean(data, 'Display', CheckObjectBoolean(data, 'display'));
            if (_display) {
                // return options.push({ id: Number(data.Id), value: String(data.Name), label: data.Name + ' (' + data['BC'] + ') (' + data['BM'] + ')' + (data['TagName'] !== '' ? ' (' + data['TagName'] + ')' : '') });
                const _name = CheckObjectStringEmpty(data, 'Name', CheckObjectStringEmpty(data, 'name'));
                const _id = CheckObjectNumber(data, 'Id', CheckObjectNumber(data, 'id'));
                const _bc = CheckObjectStringEmpty(data, 'BC', CheckObjectStringEmpty(data, 'bc'));
                const _bm = CheckObjectStringEmpty(data, 'BM', CheckObjectStringEmpty(data, 'bm'));
                const _tag = CheckObjectStringEmpty(data, 'TagName', CheckObjectStringEmpty(data, 'tagName'));
                const _label = _name
                    + (CheckNullValue(_bc) === null ? '' : ' (' + _bc + ')')
                    + (CheckNullValue(_bm) === null ? '' : ' (' + _bm + ')')
                    + (CheckNullValue(_tag) === null ? '' : ' (' + _tag + ')');
                options.push({ id: _id, value: _name, label: _label });
            }
            return null;
        });
        if (isDevMode)
            console.log('Populate_SubjectOptions', JSON.stringify(options));
        useAppService.getState().setSubjectOptions(options);
        await Delay(0);
    }
}

const Populate_DialingCodeOptions = async () => {
    const items = useAppService.getState().dialingCodes;
    if (Array.isArray(items) && items.length > 0) {
        let options = [];
        items.map((data, key) => {
            const _value = CheckObjectStringEmpty(data, 'Value', CheckObjectStringEmpty(data, 'value'));
            const _name = CheckObjectStringEmpty(data, 'Text', CheckObjectStringEmpty(data, 'text'));
            const _id = CheckObjectNumber(data, 'Id', CheckObjectNumber(data, 'id'));
            return options.push({ value: _value, label: _name, id: _id });
        });
        if (isDevMode)
            console.log('Populate_DialingCodeOptions', JSON.stringify(options));
        useAppService.getState().setDialingCodeOptions(options);
        await Delay(0);
    }
}

const Populate_ClassroomOptions = async () => {
    const items = useAppService.getState().classrooms;
    if (Array.isArray(items) && items.length > 0) {
        let options = [];
        items.map((data, key) => {
            // const _value = CheckObjectStringEmpty(data, 'Value', CheckObjectStringEmpty(data, 'value'));
            // const _name = CheckObjectStringEmpty(data, 'Text', CheckObjectStringEmpty(data, 'text'));
            // const _id = CheckObjectNumber(data, 'Id', CheckObjectNumber(data, 'id'));
            if (options.findIndex(x => String(x.value) === String(data)) < 0)
                options.push({ value: data, label: data, id: key });
            return null;
        });
        if (isDevMode)
            console.log('Populate_ClassroomOptions', JSON.stringify(options));
        useAppService.getState().setClassroomOptions(options);
        await Delay(0);
    }
}